import React, { useEffect, useState, useCallback } from "react";
import './footer.scss';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginModal, FooterLoginModal, headerLoginModal, countCart, storeFavCount, logoDetail } from "../../../Redux/action";
import { isEmpty, RandomId } from "../../../CommanFunctions/commanFunctions";
import Commanservice from "../../../CommanService/commanService";
import Paypal from './../../../Assets/Images/Paypal.webp';
import mastercard from './../../../Assets/Images/Mastercard.webp';
import visa from './../../../Assets/Images/Visa.webp';
import amex from './../../../Assets/Images/AmericanExpress.webp';
import Rupay from './../../../Assets/Images/Rupay.webp';
import UPI from './../../../Assets/Images/UPI.webp';
import Form from 'react-bootstrap/Form';
import Notification from "../../../CommanUIComp/Notification/Notification";
import SignIn from "../../Login/signIn";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CrispChat from "./CrispChat";

const Footer = () => {
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();
    const location = useLocation();
    const isLogin = Object.keys(selector.loginData).length > 0;

    // Tost Msg
    const [toastShow, setToastOpen] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    // Footer Active
    const [FooterActive, setFooterActive] = useState("");

    // Footer Data
    const [footerLogo, setFooterLogo] = useState([]);
    const [footerContent, setFooterContent] = useState([]);
    const [footerSocialLink, setfooterSocialLink] = useState([]);
    const [footerContact, setFooterContact] = useState([]);

    // Form email
    const [email, setEmail] = useState("");

    const footerData = useCallback((data) => {
        const obj = {
            "a": "getHomeFooterDetail",
            "store_id": data.mini_program_id,
            "user_id": Object.keys(selector.loginData).length > 0 ? selector.loginData.member_id : RandomId,
            "type": "B2C"
        }
        Commanservice.postLaravelApi("/SectionDetail", obj).then((res) => {
            if (res["data"]["success"] === 1) {
                setFooterContent([])
                const footerData = res["data"]["data"];
                if (Object.keys(footerData).length > 0) {
                    const footerLogo = footerData["logo_data"];
                    const footerContentData = footerData["content_data"];
                    const footerSocialData = footerData["socialmedia_link"];
                    const footerContact = footerData["contact_data"];
                    if (footerLogo.length > 0) {
                        setFooterLogo(footerLogo);
                        dispatch(logoDetail(footerLogo))
                    }
                    //console.log(footerContentData);
                    //let arr = [];
                    // arr.push( { "code": "about-us", "name": "About Us" })
                    // if (footerSocialData.length > 0) {
                    //     footerContentData.map((e) => { arr.push(e); return e; });
                    // }

                    setFooterContent(footerContentData);
                    if (footerSocialData.length > 0) {
                        setfooterSocialLink(footerSocialData);
                    }
                    if (footerContact.length > 0) {
                        footerContact[0]['address'] = footerContact[0]['building']
                        if (isEmpty(footerContact[0]['building_name']) != '') {
                            footerContact[0]['address'] = footerContact[0]['address'] + ', ' + footerContact[0]['building_name']
                        }
                        footerContact[0]['address'] = footerContact[0]['address'] + ', ' + footerContact[0]['street'];
                        if (isEmpty(footerContact[0]['description']) != '') {
                            footerContact[0]['address'] = footerContact[0]['address'] + ', ' + footerContact[0]['description']
                        }
                        if (isEmpty(footerContact[0]['city_name']) != '') {
                            footerContact[0]['address'] = footerContact[0]['address'] + ', ' + footerContact[0]['city_name']
                        }
                        if (isEmpty(footerContact[0]['pincode']) != '') {
                            footerContact[0]['address'] = footerContact[0]['address'] + '-' + footerContact[0]['pincode']
                        }
                        footerContact[0]['address'] = footerContact[0]['address'] + ', ' + footerContact[0]['state_name'] + ', ' + footerContact[0]['country_name'] + '.'
                        setFooterContact(footerContact);
                    }
                    dispatch(storeFavCount(footerData?.favourite_count));
                    dispatch(countCart(footerData?.cart_count));
                } else {
                    let arr = [];
                    arr.push({ "code": "blog", "name": "Blog" }, { "code": "about-us", "name": "About Us" }, { "code": "contact-us", "name": "Contact Us" })
                    setFooterContent(arr);
                    dispatch(storeFavCount(selector.storeFavCount));
                    dispatch(countCart(selector.countCart));
                }
            } else {
                let arr = [];
                arr.push({ "code": "blog", "name": "Blog" }, { "code": "about-us", "name": "About Us" }, { "code": "contact-us", "name": "Contact Us" })
                setFooterContent(arr);
                setToastOpen(true)
                setIsSuccess(false)
                setToastMsg(res.data.message)
                dispatch(storeFavCount(selector.storeFavCount));
                dispatch(countCart(selector.countCart));
            }

        }).catch(() => {
            let arr = [];
            arr.push({ "code": "blog", "name": "Blog" }, { "code": "about-us", "name": "About Us" }, { "code": "contact-us", "name": "Contact Us" })
            setFooterContent(arr);
            dispatch(storeFavCount(selector.storeFavCount));
            dispatch(countCart(selector.countCart));
        })
    }, [selector.loginData, dispatch])

    const subscribeEmail = () => {
        const obj = {
            a: "AddUpdateSubscribers",
            unique_id: "",
            store_id: selector.storeEntityId.mini_program_id,
            email: email,
        };

        if (isEmpty((email)) != '') {
            Commanservice.postLaravelApi('/Subscribers', obj).then((res) => {
                if (res.data.success === 1) {
                    setEmail("");
                    setToastOpen(true);
                    setIsSuccess(true);
                    setToastMsg(res.data.message);
                    if (!isLogin) {
                        dispatch(loginModal(true));
                        dispatch(FooterLoginModal(true));
                        dispatch(headerLoginModal(false));
                    }
                } else {
                    setToastOpen(true);
                    setIsSuccess(false);
                    setToastMsg(res.data.message);
                }
            }).catch(() => { })
        } else {
            setToastOpen(true);
            setIsSuccess(false);
            setToastMsg('Please Enter Valid Email.');
        }
    };

    useEffect(() => {
        const data = selector.storeEntityId;
        if (Object.keys(data).length > 0) {
            footerData(data);
            setFooterContent([]);
        }
    }, [selector.storeEntityId, selector.loginData]);

    return (
        <React.Fragment>
            <section id='Footer' className='footer_section'>
                <div className='footer-offer-box'>
                    <div className='offer-box'>
                        {/* {footerLogo.length > 0 && footerLogo.map((L, i) => {
                            return (
                                <React.Fragment key={i}>
                                    {L.image !== "" ? (
                                        <React.Fragment>
                                            {L.logo_type === "FOOTER" ? (
                                                <Link to={'/'} className='footer-logo '>
                                                    <div className="footer-logo-resp">
                                                        <LazyLoadImage effect="blur" src={L.image} alt='' className='img-fluid' />
                                                    </div>
                                                </Link>
                                            ) : ("")}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {L.logo_type === "FOOTER" ? (
                                                <Link to={'/'} className='footer-logo '>
                                                    <div className="footer-logo-resp">
                                                        <LazyLoadImage effect="blur" src={NoRF} alt='' className='img-fluid' />
                                                    </div>
                                                </Link>
                                            ) : ("")}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            );
                        })} */}

                        <h2 className='offer-box-titla text-center fs-14px'>You’re invited! Join our mailing list to get 12% off on your first order, new launches and more!</h2>
                        <div className='input-field-subscribe'>
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Enter Your email" className='rounded-0 ' value={email} onChange={(event) => { setEmail(event.target.value) }} />
                                </Form.Group>

                                <div className="">
                                    {email !== "" ?
                                        <button type="button" className='subscribe-bg-color btn sign-up-hide h-45px' onClick={() => { subscribeEmail(); }} aria-label="Subscribe to our newsletter">
                                            Subscribers
                                        </button> :
                                        <button type="button" className='subscribe-bg-color btn sign-up-hide h-45px' aria-label="Subscribe to our newsletter">
                                            Subscribers
                                        </button>}
                                    <button type="button" className='subscribe-bg-color h-45px send-hide' onClick={() => { subscribeEmail(); }} aria-label="Subscribe to our newsletter">
                                        <i className="ic_send fs-20px"></i>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="footer_section_menu border_bottom">
                        <div className='row'>
                            {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <div className='fs-14px page-detail'>
                                    <h3 className='mb-3 fs-20px footer-heading'>Customer Service</h3>
                                    <ul className='ps-0'>
                                        {footerContent.length > 0 && footerContent.map((e, i) => {
                                            return (
                                                <li key={i} className='foot-link'>
                                                    <Link onClick={() => { setFooterActive(e.code); }} className={`text-start ${e.code === FooterActive ? "Footer-Active" : ""} `} to={`/${e.name}`}>
                                                        {e.name}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div> */}

                            {footerContent.length > 0 && (footerContent || []).map((e, i) => {
                                if (e.category_name === 'Customer Service') {
                                    return <div className='col-12 col-sm-6 col-md-4 col-lg-3 my-3' key={i}>
                                        <div className='fs-14px page-detail'>
                                            <h3 className='mb-3 fs-20px footer-heading'>{e.category_name}</h3>
                                            <ul className='ps-0'>
                                                {(e.data || []).map((d, index) => {
                                                    return (<li key={index} className='foot-link'>
                                                        <Link onClick={() => { setFooterActive(d.ecm_code); }} className={`text-start ${d.ecm_code === FooterActive ? "Footer-Active" : ""} `} to={`/${(d.ecm_code).toLowerCase()}`}>
                                                            {d.ecm_name}
                                                        </Link>
                                                    </li>)
                                                }
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                if (e.category_name === 'About Us') {
                                    return <div className='col-12 col-sm-6 col-md-4 col-lg-3 my-3 ' key={i}>
                                        <div className='fs-14px page-detail'>
                                            <h3 className='mb-3 fs-20px footer-heading'>{e.category_name}</h3>
                                            <ul className='ps-0'>
                                                <li className="foot-link">
                                                    <Link className={`text-start ${location.pathname.includes("/about-us") ? "Footer-Active" : ""}`} to={'/about-us'} onClick={() => { setFooterActive(""); }}>About Us</Link>
                                                </li>
                                                {(e.data || []).map((d, index) => {
                                                    return (<li key={index} className='foot-link'>
                                                        <Link onClick={() => { setFooterActive(d.ecm_code); }} className={`text-start ${d.ecm_code === FooterActive ? "Footer-Active" : ""} `} to={`/${(d.ecm_code).toLowerCase()}`}>
                                                            {d.ecm_name}
                                                        </Link>
                                                    </li>)
                                                }
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                if (e.category_name === 'Why') {
                                    return <div className='col-12 col-sm-6 col-md-4 col-lg-3 my-3 ' key={i}>
                                        <div className='fs-14px page-detail'>
                                            <h3 className='mb-3 fs-20px footer-heading'>{e.category_name}</h3>
                                            <ul className='ps-0'>
                                                <li className="foot-link">
                                                    <Link className={`text-start ${location.pathname.includes("/contact") ? "Footer-Active" : ""}`} to={'/contact-us'} onClick={() => { setFooterActive(""); }}>24/7 Customer Support</Link>
                                                </li>
                                                {(e.data || []).map((d, index) => {
                                                    return (<li key={index} className='foot-link'>
                                                        <Link onClick={() => { setFooterActive(d.ecm_code); }} className={`text-start ${d.ecm_code === FooterActive ? "Footer-Active" : ""} `} to={`/${(d.ecm_code).toLowerCase()}`}>
                                                            {d.ecm_name}
                                                        </Link>
                                                    </li>)
                                                }
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                }

                            }
                            )}

                            <div className='col-12 col-sm-6 col-md-4 col-lg-3 my-3 '>
                                {footerContact.length > 0 &&
                                    <div className='fs-14px page-detail'>
                                        <h3 className='mb-3 fs-20px footer-heading'>Get In Touch</h3>
                                        {footerContact.length > 0 && footerContact.map((e, i) => (
                                            <ul className='ps-0' key={i}>
                                                <li className='foot-link d-flex align-items-start'>
                                                    <div className="icon"> <i className="ic_home" /> </div>
                                                    <div className="ms-2 fs-13px">{e?.address}</div>
                                                </li>
                                                {isEmpty(e?.email) != '' ?
                                                    <li className='foot-link d-flex align-items-start'>
                                                        <div className="icon"><i className="ic_envelope" /></div>
                                                        <a href={`mailto:${e?.email}`} className="footer-text-color footer-text-size footer-text-hover ms-2 fs-13px"> {e?.email}</a>
                                                    </li>
                                                    : ''}
                                                {isEmpty(e?.mobile) != '' ?
                                                    <li className='foot-link d-flex align-items-start'>
                                                        <div className="icon"><i className="ic_telephone" /></div>
                                                        <a href={`tel:${e?.mobile}`} className="footer-text-color footer-text-size footer-text-hover ms-2 fs-13px">{e?.country_code} {e?.mobile}</a>
                                                    </li>
                                                    : ''}
                                            </ul>
                                        ))}
                                    </div>
                                }
                                {footerSocialLink.length > 0 &&
                                    <div className='fs-14px page-detail stay-connected'>
                                        <h3 className='mb-3 fs-20px footer-heading'>Stay Connected</h3>
                                        <ul className='ps-0 mb-0'>
                                            <li className='foot-link'>Write a Product Review
                                                Text to Save</li>
                                            <li>
                                                <ul className='ps-0'>
                                                    {footerSocialLink.length > 0 &&
                                                        footerSocialLink.map((s, i) => {
                                                            return (
                                                                <li className='me-3 social-icon' key={i}><a className="text-start" target={"_blank"} href={s.url} ><img src={s.image} alt={s.name} className="img-fluid wh-auto" width={31} height={31} /></a></li>
                                                            );
                                                        })}
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-payment-icons">
                    <div className="payment-icon">
                        <LazyLoadImage effect="blur" src={Paypal} alt="Paypal" className='img-fluid' width={170} height={40} />
                    </div>
                    <div className="payment-icon">
                        <LazyLoadImage effect="blur" src={visa} alt='Visa' className='img-fluid' width={170} height={40} />
                    </div>
                    <div className="payment-icon">
                        <LazyLoadImage effect="blur" src={mastercard} alt='Mastercard' className='img-fluid' width={170} height={40} />
                    </div>
                    <div className="payment-icon">
                        <LazyLoadImage effect="blur" src={amex} alt='Amex' className='img-fluid' width={170} height={40} />
                    </div>
                    <div className="payment-icon">
                        <LazyLoadImage effect="blur" src={Rupay} alt='Rupay' className='img-fluid' width={170} height={40} />
                    </div>
                    <div className="payment-icon">
                        <LazyLoadImage effect="blur" src={UPI} alt='UPI' className='img-fluid' width={170} height={40} />
                    </div>
                </div>
                <div className="copyright_info">
                    <div className="container">
                        <p className='copyright-color text-center py-3 mb-0 fs-15px'>Copyright © {new Date().getFullYear()} {selector.storeEntityId.store_name}</p>
                    </div>
                </div>
            </section>

            {selector.FooterLoginModal && <SignIn logo={footerLogo} data={footerLogo} />}

            <Notification toastMsg={toastMsg} toastShow={toastShow} isSuccess={isSuccess} Close={() => setToastOpen()} />
            <CrispChat />

        </React.Fragment>
    );
}

export default Footer