import React, { useState, useEffect } from 'react';
import './header.scss';
import { useCallback } from "react";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { loginModal, headerLoginModal, FooterLoginModal, HeaderLogoData, diamondPageChnages, storeCurrency, storeCurrencyData, selectedJewelRing, editDiamondAction, selectedRingData, selectedDiamondObject, addFilterAction, SelectFilterAction, selectDiamondAction, diamondNumber, addedRingData, IsSelectedDiamond, isRingSelected, addedDiamondData, storeFilteredData, storeActiveFilteredData, storeSelectedDiamondData, jeweleryDIYimage, storeSelectedDiamondPrice, storeSpecData, storeProdData, storeDiamondNumber, diamondShape, diamondImage, finalCanBeSetData, diamondSelectShape, jewelSelectedCategory, storeEmbossingData, saveEmbossings, previewImageDatas, activeImageData } from "../../../Redux/action";
import Commanservice from "../../../CommanService/commanService";
import { isEmpty, jewelVertical, RandomId, } from '../../../CommanFunctions/commanFunctions';
import Notification from '../../../CommanUIComp/Notification/Notification';
import SignIn from '../../Login/signIn';
import SearchProducts from '../SearchProducts/SearchProducts';

const Header = () => {
    const selector = useSelector(state => state)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    // Toast Msg 
    const [toastShow, setToastOpen] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const ResetPasswordURL = location.pathname.includes("reset-password");
    const isLogin = Object.keys(selector.loginData).length > 0;
    var megaMenu = sessionStorage.getItem("megaMenu");

    // responsive Header
    const [className, setClassName] = useState("");
    const [navbarToggle, setNavbarToggle] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    // One time updet API
    const [onceUpdatedLogo, setOnceUpdatedLogo] = useState(false);

    // Header Data
    const [storeHeaderLogo, setStoreHeaderLogo] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [currencyCode, setCurrencyCode] = useState([]);

    // Navbar Data
    const [navMenuFirst, setNavMenuFirst] = useState([])
    const [navMenuSecond, setNavMenuSecond] = useState([]);

    const [scroll, setScroll] = useState(false);

    const setHeaderLogo = (data) => {
        // if (Object.keys(data).length > 0) {
        const filterLogo = isEmpty(data) !== "" ? data.filter((h) => h.logo_type === "HEADER") : JSON.parse(megaMenu)?.logo_data.filter((h) => h.logo_type === "HEADER");
        setStoreHeaderLogo(filterLogo);
        dispatch(HeaderLogoData(filterLogo));
        const favLogo = isEmpty(data) !== "" ? data.filter((h) => h.logo_type === "FAVICON") : JSON.parse(megaMenu)?.logo_data.filter((h) => h.logo_type === "FAVICON");
        if (isEmpty(favLogo) !== '' && favLogo.length > 0) {
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }

            if (favLogo?.length > 0) {
                link.href = favLogo?.[0]?.image;
            }
        }
        // } else {
        //     setStoreHeaderLogo([]);
        // }

    }

    const headerSectionData = useCallback(() => {
        if (megaMenu === undefined || megaMenu === null) {
            const obj = {
                "a": "GetHomeNavigation",
                "store_id": selector.storeEntityId.mini_program_id,
                "type": "B2C"
            }
            Commanservice.postLaravelApi("/NavigationMegamenu", obj).then((res) => {
                if (res.data.success === 1) {
                    const data = res.data.data;
                    sessionStorage.setItem("megaMenu", JSON.stringify(data));
                    var navigation_data = isEmpty(data['navigation_data']);
                    var logo_data = isEmpty(data['logo_data']);
                    var currency_data = isEmpty(data['currency_data']);
                    var contact_data = isEmpty(data['contact_data']);
                    setCurrencyCode(currency_data);
                    setHeaderLogo(logo_data);
                    setContactData(contact_data);
                    if (navigation_data.length > 0) {
                        for (let c = 0; c < navigation_data.length; c++) {
                            var diy_detaills = [];
                            var sub_menu = navigation_data[c]['sub_menu'];
                            for (let d = 0; d < sub_menu.length; d++) {
                                var detaills = sub_menu[d]['detaills'];
                                if (detaills.length != 0) {
                                    if (detaills.length > 10) {
                                        const arrayDetails = 2
                                        const resultDetails = [[], []]
                                        const dataDetails = Math.ceil(detaills.length / 2)
                                        for (let c = 0; c < arrayDetails; c++) {
                                            for (let i = 0; i < dataDetails; i++) {
                                                const valueDetails = detaills[i + c * dataDetails]
                                                if (!valueDetails) continue
                                                resultDetails[c].push(valueDetails)
                                            }
                                        }
                                        if (resultDetails.length > 0) {
                                            for (let e = 0; e < resultDetails.length; e++) {
                                                if (resultDetails[e].length > 0) {
                                                    for (let d = 0; d < resultDetails[e].length; d++) {
                                                        if (resultDetails[e][d].logic_code == "size_group") {
                                                            resultDetails[e][d].logic_code = 'carat'
                                                        }
                                                        if ((resultDetails[e][d].logic_code).includes('_') == true) {
                                                            var output = (resultDetails[e][d].logic_code.split('_')).pop();
                                                            resultDetails[e][d].titles = resultDetails[e][d].title.replaceAll(' ', '-');
                                                            resultDetails[e][d]['router_link'] = navigation_data[c].router_link + '/' + output + '/' + (resultDetails[e][d].titles).toLowerCase()
                                                        } else {
                                                            resultDetails[e][d].titles = resultDetails[e][d].title.replaceAll(' ', '-');
                                                            resultDetails[e][d]['router_link'] = navigation_data[c].router_link + '/' + resultDetails[e][d].logic_code + '/' + (resultDetails[e][d].titles).toLowerCase()
                                                        }
                                                    }
                                                }
                                            }
                                            navigation_data[c]['sub_menu'][d].detaills = [...resultDetails];
                                        }
                                    } else {
                                        for (let e = 0; e < detaills.length; e++) {
                                            if (detaills[e].logic_code == "size_group") {
                                                detaills[e].logic_code = 'carat'
                                            }
                                            if ((detaills[e].logic_code).includes('_') == true) {
                                                var output = (detaills[e].logic_code.split('_')).pop();
                                                detaills[e].titles = detaills[e].title.replaceAll(' ', '-');
                                                detaills[e]['router_link'] = navigation_data[c].router_link + '/' + output + '/' + (detaills[e].titles).toLowerCase()
                                            } else {
                                                detaills[e].titles = detaills[e].title.replaceAll(' ', '-');
                                                detaills[e]['router_link'] = navigation_data[c].router_link + '/' + detaills[e].logic_code + '/' + (detaills[e].titles).toLowerCase()
                                            }
                                        }
                                    }
                                } else {
                                    if (sub_menu[d]['product_vertical'] == 'DIY') {
                                        var datas = { title: sub_menu[d]['display_name'], router_link: '/start-with-a-setting' }
                                        diy_detaills.push(datas);
                                        sub_menu[0]['detaills'] = diy_detaills;
                                    } else {
                                        if (sub_menu[d].sub_category == 'JCYDI' || sub_menu[d].sub_category == 'CFYDI' || sub_menu[d].sub_category == 'LGFYDI' || sub_menu[d].sub_category == 'GEFYDI') {
                                            var datas = { title: 'Start With a Setting', router_link: '/start-with-a-setting', icon: 'ic_certi_diamond_jewelry' }
                                            detaills.push(datas)
                                        } else {
                                            detaills = sub_menu[d]['detaills']
                                        }
                                    }
                                }
                            }
                        }
                        const n = 2
                        const result = [[], []]

                        const navigationData = Math.ceil(navigation_data.length / 2)
                        for (let c = 0; c < n; c++) {
                            for (let i = 0; i < navigationData; i++) {
                                const value = navigation_data[i + c * navigationData]
                                if (!value) continue
                                result[c].push(value)
                            }
                        }
                        setNavMenuFirst([...result[0]]);
                        setNavMenuSecond([...result[1]]);
                    }

                    if (isEmpty(currency_data) && currency_data.length > 0) {
                        const arr1 = currency_data;
                        if (arr1.length > 0) {
                            if (arr1.length > 1) {
                                dispatch(storeCurrencyData(arr1));
                                const arr2 = arr1.filter((e) => e?.is_default === 1)
                                if (arr2.length > 0) {
                                    updateCartCurrency(isEmpty(arr2[0].mp_store_price));
                                } else {
                                    updateCartCurrency(isEmpty(arr1[0].mp_store_price));
                                }
                            } else {
                                dispatch(storeCurrencyData(arr1));
                                updateCartCurrency(isEmpty(arr1[0].mp_store_price));
                            }
                        }
                    }
                } else {
                    setToastOpen(true);
                    setIsSuccess(false);
                    setToastMsg(res.data.message);
                }
            }).catch(() => { })
        } else {
            const data = JSON.parse(megaMenu);
            var navigation_data = isEmpty(data['navigation_data']);
            var logo_data = isEmpty(data['logo_data']);
            var contact_data = isEmpty(data['contact_data']);
            setHeaderLogo(logo_data);
            setContactData(contact_data);
            if (navigation_data.length > 0) {
                for (let c = 0; c < navigation_data.length; c++) {
                    var diy_detaills = [];
                    var sub_menu = navigation_data[c]['sub_menu'];
                    for (let d = 0; d < sub_menu.length; d++) {
                        var detaills = sub_menu[d]['detaills'];
                        if (detaills.length != 0) {
                            if (detaills.length > 10) {
                                const arrayDetails = 2
                                const resultDetails = [[], []]
                                const dataDetails = Math.ceil(detaills.length / 2)
                                for (let c = 0; c < arrayDetails; c++) {
                                    for (let i = 0; i < dataDetails; i++) {
                                        const valueDetails = detaills[i + c * dataDetails]
                                        if (!valueDetails) continue
                                        resultDetails[c].push(valueDetails)
                                    }
                                }
                                if (resultDetails.length > 0) {
                                    for (let e = 0; e < resultDetails.length; e++) {
                                        if (resultDetails[e].length > 0) {
                                            for (let d = 0; d < resultDetails[e].length; d++) {
                                                if (resultDetails[e][d].logic_code == "size_group") {
                                                    resultDetails[e][d].logic_code = 'carat'
                                                }
                                                if ((resultDetails[e][d].logic_code).includes('_') == true) {
                                                    var output = (resultDetails[e][d].logic_code.split('_')).pop();
                                                    resultDetails[e][d].titles = resultDetails[e][d].title.replaceAll(' ', '-');
                                                    resultDetails[e][d]['router_link'] = navigation_data[c].router_link + '/' + output + '/' + (resultDetails[e][d].titles).toLowerCase()
                                                    if (jewelVertical(navigation_data[c]['product_vertical_name']) == true) {
                                                        // resultDetails[e][d]['router_link'] = navigation_data[c].router_link + '/' + navigation_data[c]['product_vertical_name'] + '/' + output + '/' + (resultDetails[e][d].titles).toLowerCase()
                                                    }
                                                } else {
                                                    resultDetails[e][d].titles = resultDetails[e][d].title.replaceAll(' ', '-');
                                                    resultDetails[e][d]['router_link'] = navigation_data[c].router_link + '/' + resultDetails[e][d].logic_code + '/' + (resultDetails[e][d].titles).toLowerCase()
                                                    if (jewelVertical(navigation_data[c]['product_vertical_name']) == true) {
                                                        // resultDetails[e][d]['router_link'] = navigation_data[c].router_link + '/' + navigation_data[c]['product_vertical_name'] + '/' + output + '/' + (resultDetails[e][d].titles).toLowerCase()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    navigation_data[c]['sub_menu'][d].detaills = [...resultDetails];
                                }
                            } else {
                                for (let e = 0; e < detaills.length; e++) {
                                    if (detaills[e].logic_code == "size_group") {
                                        detaills[e].logic_code = 'carat'
                                    }

                                    if ((detaills[e].logic_code).includes('_') == true) {
                                        var output = (detaills[e].logic_code.split('_')).pop();
                                        detaills[e].titles = detaills[e].title.replaceAll(' ', '-');
                                        detaills[e]['router_link'] = navigation_data[c].router_link + '/' + output + '/' + (detaills[e].titles).toLowerCase()
                                        if (jewelVertical(navigation_data[c]['product_vertical_name']) == true) {
                                            // detaills[e]['router_link'] = navigation_data[c].router_link + '/' + navigation_data[c]['product_vertical_name'] + '/' + output + '/' + (detaills[e].titles).toLowerCase()
                                        }
                                    } else {
                                        detaills[e].titles = detaills[e].title.replaceAll(' ', '-');
                                        detaills[e]['router_link'] = navigation_data[c].router_link + '/' + detaills[e].logic_code + '/' + (detaills[e].titles).toLowerCase()
                                        if (jewelVertical(navigation_data[c]['product_vertical_name']) == true) {
                                            // detaills[e]['router_link'] = navigation_data[c].router_link + '/' + navigation_data[c]['product_vertical_name'] + '/' + output + '/' + (detaills[e].titles).toLowerCase()
                                        }
                                    }
                                }
                            }
                        } else {
                            if (sub_menu[d]['product_vertical'] == 'DIY') {
                                var datas = { title: sub_menu[d]['display_name'], router_link: '/start-with-a-setting' }
                                diy_detaills.push(datas)
                                sub_menu[0]['detaills'] = diy_detaills;
                            } else {
                                if (sub_menu[d].sub_category == 'JCYDI' || sub_menu[d].sub_category == 'CFYDI' || sub_menu[d].sub_category == 'LGFYDI' || sub_menu[d].sub_category == 'GEFYDI') {
                                    var datas = { title: 'Start With a Setting', router_link: '/start-with-a-setting', icon: 'ic_certi_diamond_jewelry' }
                                    detaills.push(datas)
                                } else {
                                    detaills = sub_menu[d]['detaills']
                                }
                            }
                        }
                    }
                }
                const n = 2
                const result = [[], []]
                const navigationData = Math.ceil(navigation_data.length / 2)
                for (let c = 0; c < n; c++) {
                    for (let i = 0; i < navigationData; i++) {
                        const value = navigation_data[i + c * navigationData]
                        if (!value) continue
                        result[c].push(value)
                    }
                }
                setNavMenuFirst([...result[0]]);
                setNavMenuSecond([...result[1]]);
            }
        }
    }, [selector.storeEntityId, dispatch, setHeaderLogo]);

    const updateCartCurrency = (e) => {
        const obj = {
            a: "updateCartCurrency",
            store_id: selector.storeEntityId.mini_program_id,
            member_id: Object.keys(selector.loginData).length > 0 ? selector.loginData.member_id : RandomId,
            new_currency: e
        }
        Commanservice.postLaravelApi("/CartMaster ", obj).then((res) => {
            if (res.data.success !== 1) {
                setToastOpen(true);
                setIsSuccess(false);
                setToastMsg(res.data.message);
            }
        }).catch(() => { })
    }

    const showLoginPage = () => {
        dispatch(loginModal(true));
        dispatch(headerLoginModal(true));
        dispatch(FooterLoginModal(false));
    }

    const routerChange = (event, link, urlName) => {
        if (!urlName) {
            dispatch(editDiamondAction(""));
            dispatch(selectedJewelRing({}));
            dispatch(selectedRingData({}));
            dispatch(selectedDiamondObject({}));
            dispatch(selectDiamondAction({}));
            dispatch(diamondNumber(""));
            dispatch(storeDiamondNumber(""));
            dispatch(addedDiamondData({}));
            dispatch(addedRingData({}));
            dispatch(IsSelectedDiamond(false));
            dispatch(isRingSelected(false));
            dispatch(storeFilteredData({}))
            dispatch(storeActiveFilteredData({}));
            dispatch(storeSelectedDiamondData({}));
            dispatch(jeweleryDIYimage(""));
            dispatch(storeSelectedDiamondPrice(""))
            dispatch(storeSpecData({}))
            dispatch(storeProdData({}))
            dispatch(diamondShape(""))
            dispatch(diamondImage(""))
            dispatch(finalCanBeSetData([]));
            dispatch(diamondSelectShape({}));
            dispatch(jewelSelectedCategory({}))
            dispatch(storeEmbossingData([]));
            dispatch(saveEmbossings(false));
            dispatch(previewImageDatas([]));
            dispatch(activeImageData([]));
        }
        if (!window.location.pathname.includes('products')) {
            dispatch(addFilterAction([]));
            dispatch(SelectFilterAction(true));
            dispatch(storeSelectedDiamondData({}));
            dispatch(diamondSelectShape({}));
            dispatch(jewelSelectedCategory({}))
            dispatch(storeEmbossingData([]));
            dispatch(saveEmbossings(false));
            dispatch(previewImageDatas([]));
            dispatch(activeImageData([]));
        }
        if (link !== "") {
            // if (window.location.pathname !== link) {
            event.stopPropagation()
            event.preventDefault()
            navigate("/-");
            // }
            sessionStorage.setItem("storeUrl", link);
        }
        dispatch(diamondPageChnages(false));
        setNavbarToggle(false);
        sessionStorage.removeItem('filterJson');
    }

    const handleFilter = (titleVal) => {
        if (!(selector.productNameList || []).includes(titleVal)) {
            dispatch(addFilterAction([...selector.productNameList, titleVal]))
        }
    };

    const changeCurrency = (e) => {
        sessionStorage.setItem("storeUrl", window.location.pathname);
        e.stopPropagation();
        e.preventDefault();

        const data = selector.storeCurrencyData.filter((s) => s.mp_store_price === e.target.value);

        if (data[0]?.mp_b2c_url && data[0].is_store !== 1) {
            window.open(data[0]?.mp_b2c_url, '_blank', '');
        } else {
            updateCartCurrency(data[0].mp_store_price);
            dispatch(storeCurrency(data[0].mp_store_price));
            if (!location.pathname.includes("/start-with-a-setting")) {
                navigate("/-");
            }
        }
    }

    useEffect(() => {
        if (!onceUpdatedLogo) {
            setOnceUpdatedLogo(true);
            headerSectionData();
            if (ResetPasswordURL) {
                dispatch(headerLoginModal(true));
                dispatch(loginModal(true));
            }
        }
        setInterval(() => {
            if (!window.matchMedia("(max-width: 992px)").matches) {
                window.addEventListener("scroll", () => {
                    setScroll(window.scrollY > 80);
                });
            } else {
                window.addEventListener("scroll", () => {
                    setScroll(window.scrollY > 0);
                });
            }
        }, 1000);
    }, [dispatch]);

    useEffect(() => {
        if (selector?.storeCurrencyData.length > 0) {
            updateCartCurrency(selector?.storeCurrencyData[0].mp_store_price);
            dispatch(storeCurrency(selector?.storeCurrencyData[0].mp_store_price));
        }
        if (!location.pathname.includes('start-with-a-diamond')) {
            dispatch(editDiamondAction(""));
            dispatch(selectedJewelRing({}));
            dispatch(selectedRingData({}));
            dispatch(selectedDiamondObject({}));
            dispatch(selectDiamondAction({}));
            dispatch(diamondNumber(""));
            dispatch(storeDiamondNumber(""));
            dispatch(addedDiamondData({}));
            dispatch(addedRingData({}));
            dispatch(IsSelectedDiamond(false));
            dispatch(isRingSelected(false));
            dispatch(storeFilteredData({}))
            dispatch(storeActiveFilteredData({}))
            dispatch(storeSelectedDiamondData({}));
            dispatch(jeweleryDIYimage(""));
            dispatch(storeSelectedDiamondPrice(""))
            dispatch(storeSpecData({}))
            dispatch(storeProdData({}))
            dispatch(diamondShape(""))
            dispatch(diamondImage(""))
            dispatch(finalCanBeSetData([]));
            dispatch(diamondSelectShape({}));
            dispatch(jewelSelectedCategory({}))
            dispatch(storeEmbossingData([]));
            dispatch(saveEmbossings(false));
            dispatch(previewImageDatas([]));
            dispatch(activeImageData([]));
        }
        if (!window.location.pathname.includes('products')) {
            dispatch(addFilterAction([]));
            dispatch(SelectFilterAction(true));
            dispatch(diamondSelectShape({}));
            dispatch(jewelSelectedCategory({}))
        }
    }, []);

    const isMenuActive = (menu, item) => {
        return location.pathname
            .toLowerCase()
            .toString()
            .includes(`/${menu.split(" ").join("-").toLowerCase()}`);
    };
    const isActiveParentMenu = (menus, item) => {
        if (menus !== "" && (menus === location.pathname || location.pathname.includes(menus))) {
            return true;
        } else if (
            item.product_vertical_name === "DIY" &&
            location.pathname.includes("start-with-a")
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <React.Fragment>
            <section id='Header'>
                <div className="bg-nav topbar">
                    <div className='container'>
                        <div className='top-bar-row'>
                            <div className='top-bar-col'>
                                {contactData.length > 0 && contactData.map((e, index) => {
                                    return <ul className="nav btn-margin" key={index} >
                                        {isEmpty(e?.mobile) != '' ?
                                            <li className="nav-item">
                                                <a href={`tel:${isEmpty(e?.mobile)}`} className="nav-link"><i className="ic_telephone me-1"></i>{isEmpty(e?.country_code)} {isEmpty(e?.mobile)}</a>
                                            </li>
                                            : ''}
                                        {isEmpty(e?.email) != '' ?
                                            <li className="nav-item">
                                                <a href={`mailto:${isEmpty(e?.email)}`} className="nav-link"><i className="ic_email me-1"></i>{isEmpty(e?.email)}</a>
                                            </li>
                                            : ''}
                                    </ul>
                                })}
                            </div>
                            <div className='top-bar-col center'>
                                <p className='mb-0 top-desc'>Free Shipping | 100-Day Easy Returns</p>{/*100% Lab Grown Diamonds | */}
                                {/* <p className='mb-0 top-desc'>The Women Of Kupwara | Emblems of Empowerment</p> */}
                            </div>
                            <div className='top-bar-col'>
                                <div className='d-flex justify-content-end'>
                                    {Object.keys(selector.storeEntityId).length > 0 ? selector?.storeCurrencyData.length > 0 &&
                                        <Form.Select className='lang-country' value={selector.storeCurrencyData?.mp_store_price} id="cars"
                                            onChange={(e) => changeCurrency(e)}
                                            // onChange={(e) => {
                                            // sessionStorage.setItem("storeUrl", window.location.pathname); e.stopPropagation(); e.preventDefault(); navigate("/-"); const data = selector.storeCurrencyData.filter((s) => s.mp_store_price === e.target.value); dispatch(storeCurrency(data[0].mp_store_price)); { if (data[0].is_store !== 1) { updateCartCurrency(data[0].mp_store_price) } } if (data[0]?.mp_b2c_url) { if (data[0].is_store !== 1) { window.open(data[0]?.mp_b2c_url, '_blank', ''); } }
                                            // }}
                                            aria-label="Default select example">
                                            {isEmpty(selector.storeCurrencyData) && selector.storeCurrencyData.map((e, i) => {
                                                return (<option key={i} value={isEmpty(e.mp_store_price)}>{isEmpty(e.mp_store_price)}</option>)
                                            })}
                                        </Form.Select> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='B2cMenu '>
                    <div className='container'>
                        {/* {storeHeaderLogo.length > 0 ? storeHeaderLogo.map((h, index) => (
                            <React.Fragment key={index}>
                                <div className='z_logo'>
                                    <Link className="navbar-brand navbar-brand_02" to={"/"}>
                                        {isEmpty(h.image) !== "" ? (<img src={isEmpty(h.image)} alt="" className="img-fluid" />) : ""}
                                    </Link>
                                </div>
                            </React.Fragment>
                        )) : ("")} */}

                        <nav className="navbar navbar-expand-lg desktop-header header-shadow" id={scroll ? "fixed-header" : ""} > {/*fixed-header */}
                            <div className="header-inner  mobile-header">
                                {isEmpty(storeHeaderLogo) && storeHeaderLogo.length > 0 ? storeHeaderLogo.map((h, index) => (
                                    <React.Fragment key={index}>
                                        <Link className="navbar-brand navbar-brand_01 z_logo_align" aria-label='Logo' to={"/"} onClick={() => {
                                            setNavbarToggle(false); setClassName("");
                                            dispatch(addFilterAction([]));
                                            dispatch(SelectFilterAction(true));
                                        }}>
                                            {isEmpty(h.image) !== "" ? (<img src={isEmpty(h.image)} alt="Zurah Logo" className="img-fluid" width={160} height={29} />) : (<img src="https://dummyimage.com/150x100/ebebeb/000000.jpg" alt="dummy image" className="img-fluid" />)}
                                        </Link>
                                    </React.Fragment>
                                )) : ('')}
                                {!navbarToggle ?
                                    <button className="navbar-toggler" type="button" onClick={() => setNavbarToggle(true)} aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                        <span className="navbar-toggler-icon"></span>
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    :
                                    <button className="navbar-toggler active" type="button" onClick={() => setNavbarToggle(false)} aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                        <span className="navbar-toggler-icon"></span>
                                        <span className="navbar-toggler-icon"></span>
                                    </button>}
                                <div className={`collapse navbar-collapse d-lg-flex justify-content-lg-between ${navbarToggle && "show"}`} id="navbarNavAltMarkup">
                                    <ul className="navbar-nav main-menu">
                                        {navMenuFirst.length > 0 && navMenuFirst.map((e, index) => (
                                            <React.Fragment key={index}>
                                                <li className={`nav-item dropdown dropdown-hover position-static`}>
                                                    {e?.sub_menu.length > 1 && e.product_vertical_name != 'DIY' ?
                                                        <React.Fragment>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <Link onClick={(event) => {
                                                                    routerChange(event, e.router_link);
                                                                    sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); if (className === "") { setClassName(e.unique_id); } else { if (className === e.unique_id) { setClassName(""); } else { setClassName(e.unique_id); } }
                                                                }} className={`nav-link ${className === e.unique_id && "show "} ${isActiveParentMenu(e.router_link, e)
                                                                    ? "active"
                                                                    : ""
                                                                    }`} data-bs-toggle="dropdown" >{e.menu_name} <span className={`minus-plus ${className === e.unique_id ? "ic_minus" : "ic_plus"}`}></span></Link>
                                                            </div>
                                                            <div className={`dropdown-menu megamenu  ${className === e.unique_id && "show"}`} role="menu">
                                                                <div className="megamenu-inner">
                                                                    <div className='megamenu-left'>
                                                                        <div className='megamenu-row'>
                                                                            {isEmpty(e.sub_menu) && e?.sub_menu.length > 0 && e?.sub_menu.map((s, inx) => {
                                                                                return (
                                                                                    <React.Fragment key={inx}>
                                                                                        <div className="menu-col">
                                                                                            <div className="col-megamenu">
                                                                                                <h6 className="title mb-3 fs-16px">{isEmpty(s.display_name)}</h6>
                                                                                                {s.detaills.length > 0 ?
                                                                                                    <ul className='megamenu-menu'>
                                                                                                        {s.detaills.map((d, index) => {
                                                                                                            if (d.length > 0) {
                                                                                                                return (
                                                                                                                    d.map((f, indx) => {
                                                                                                                        if (f.title === "") {
                                                                                                                            return;
                                                                                                                        }
                                                                                                                        return (
                                                                                                                            <li key={indx} className="megamenu-menu-item inner_li">
                                                                                                                                <Link onClick={(e) => { routerChange(e, f.router_link); handleFilter(f.title); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} to={f.router_link} className={`text-start fs-13px cursor-pointer ${isMenuActive(
                                                                                                                                    f.title,
                                                                                                                                    e
                                                                                                                                )
                                                                                                                                    ? "active"
                                                                                                                                    : ""
                                                                                                                                    }`}>
                                                                                                                                    {f.icon !== "" && <span className={`${f.icon} me-2`}></span>}
                                                                                                                                    {f.title.split(" ")?.join(" ")?.toLowerCase()}
                                                                                                                                </Link>
                                                                                                                            </li>
                                                                                                                        )
                                                                                                                    }))
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <li key={index} className='megamenu-menu-item'>
                                                                                                                        <Link onClick={(e) => { routerChange(e, d.router_link); handleFilter(d.title); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} to={d.router_link} className={`text-start fs-13px cursor-pointer ${isMenuActive(
                                                                                                                            d.title,
                                                                                                                            e
                                                                                                                        )
                                                                                                                            ? "active"
                                                                                                                            : ""
                                                                                                                            }`}>
                                                                                                                            {d.icon !== "" && <span className={`${d.icon} me-2`}></span>}
                                                                                                                            {d.title.split(" ")?.join(" ")?.toLowerCase()}
                                                                                                                        </Link>
                                                                                                                    </li>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </ul>
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    {isEmpty(e.image) !== "" ?
                                                                        <div className='megamenu-right sub-menu-img'>
                                                                            <div className='position-relative ad-img text-end'>
                                                                                <img src={isEmpty(e.image)} className='img-fluid' alt="Fine Jewelry & Diamond Collections" />

                                                                                {/* <div className='position-absolute shop-now'>
                                                                                    <h2 className='fs-20px cursor-pointer'><Link to={e.banner_url} onClick={(event) => { routerChange(event, e.banner_url) }} className='text-white'><u>Shop Now</u></Link></h2>
                                                                                </div> */}
                                                                            </div>
                                                                        </div> : ""}
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <>
                                                            {e.sub_menu.length == 1 || e.product_vertical_name == 'DIY' ?
                                                                <>
                                                                    <React.Fragment>
                                                                        <div className='d-flex align-items-center justify-content-between '>
                                                                            <Link onClick={() => { sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); if (className === "") { setClassName(e.unique_id); } else { if (className === e.unique_id) { setClassName(""); } else { setClassName(e.unique_id); } } }} className={`nav-link ${className === e.unique_id && "show"} ${isActiveParentMenu(e.router_link, e)
                                                                                ? "active"
                                                                                : ""
                                                                                }`} data-bs-toggle="dropdown">{e.menu_name}
                                                                                <i className={`minus-plus ${className === e.unique_id ? "ic_minus" : "ic_plus"}`}></i>
                                                                            </Link>

                                                                        </div>
                                                                        <div className={`dropdown-content ${className === e.unique_id && "show"}`}>
                                                                            <ul className='p-0'>
                                                                                {/* {e.sub_menu.length != 0 && e.sub_menu[0].detaills.map((d, di) => {
                                                                                    console.log("O----", );
                                                                                    return <>
                                                                                        <li key={di} className="inner_li">
                                                                                            <Link onClick={(e) => { routerChange(e, d.router_link); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} to={d.router_link} className="text-start fs-15px cursor-pointer">
                                                                                                {d.icon !== "" && <i className={`${d.icon} me-2`}></i>}
                                                                                                <span>{d.title} 0000</span>
                                                                                            </Link>
                                                                                        </li>
                                                                                    </>
                                                                                }
                                                                                )} */}
                                                                                {e.sub_menu.length != 0 && e.sub_menu.map((d, di) => {
                                                                                    return <li key={di} className="inner_li">{
                                                                                        d.sub_category === "STRWD" ?
                                                                                            <Link onClick={(e) => { routerChange(e, '/start-with-a-diamond'); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} to={"/start-with-a-diamond"} className={`text-start fs-15px cursor-pointer ${isMenuActive(d.display_name, e) ? "active" : ""}`}>{d.icon !== "" && <i className={`${d.icon} me-2`}></i>}
                                                                                                <span>{d.display_name}</span>
                                                                                            </Link>
                                                                                            :
                                                                                            <Link onClick={(e) => { routerChange(e, '/start-with-a-setting'); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type') }} to={'/start-with-a-setting'} className={`text-start fs-15px cursor-pointer ${isMenuActive(d.display_name, e) ? "active" : ""}`}>
                                                                                                {d.icon !== "" && <i className={`${d.icon} me-2`}></i>}
                                                                                                <span>{d.display_name}</span>
                                                                                            </Link>}
                                                                                    </li>
                                                                                }
                                                                                )}
                                                                            </ul>
                                                                        </div>
                                                                    </React.Fragment>
                                                                </>
                                                                :
                                                                <Link onClick={(event) => { routerChange(event, e.router_link) }} className={`nav-link ${className === e.unique_id && "show"} ${isActiveParentMenu(e.router_link, e)
                                                                    ? "active"
                                                                    : ""
                                                                    }`} to={e.router_link}>{e.menu_name}  </Link>
                                                            }
                                                        </>
                                                    }
                                                </li>
                                            </React.Fragment>
                                        ))}

                                        {navMenuSecond.length > 0 && navMenuSecond.map((e, index) => (
                                            <React.Fragment key={index}>
                                                <li className={`nav-item dropdown dropdown-hover position-static`} >
                                                    {e?.sub_menu.length > 1 && e.product_vertical_name != 'DIY' ?
                                                        <React.Fragment>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <Link onClick={(event) => { routerChange(event, e.router_link); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} className={`nav-link ${className === e.unique_id && "show"} ${isActiveParentMenu(e.router_link, e)
                                                                    ? "active"
                                                                    : ""
                                                                    }`} to={e.router_link} data-bs-toggle="dropdown">{e.menu_name}</Link>
                                                                <span onClick={() => { sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); if (className === "") { setClassName(e.unique_id); } else { if (className === e.unique_id) { setClassName(""); } else { setClassName(e.unique_id); } } }} className={`minus-plus ${className === e.unique_id ? "ic_minus" : "ic_plus"}`}></span>
                                                            </div>
                                                            <div className={`dropdown-menu megamenu  ${className === e.unique_id && "show"}`} role="menu">
                                                                <div className="megamenu-inner">
                                                                    <div className='megamenu-left'>
                                                                        <div className='row '>
                                                                            {isEmpty(e.sub_menu) && e?.sub_menu.length > 0 && e?.sub_menu.map((s, inx) => {
                                                                                return (
                                                                                    <React.Fragment key={inx}>
                                                                                        <div className="col-lg-3 menu-col">
                                                                                            <div className="col-megamenu">
                                                                                                <h6 className="title mb-3 fs-16px">{isEmpty(s.display_name)}</h6>
                                                                                                {s.detaills.length > 0 ?
                                                                                                    <ul className='megamenu-menu'>
                                                                                                        {s.detaills.map((d, index) => (
                                                                                                            <li className='megamenu-menu-item' key={index}>
                                                                                                                <Link onClick={(e) => { routerChange(e, d.router_link); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} to={d.router_link} className={`text-start fs-13px cursor-pointer ${isMenuActive(
                                                                                                                    d.title,
                                                                                                                    e
                                                                                                                )
                                                                                                                    ? "active"
                                                                                                                    : ""
                                                                                                                    }`}>
                                                                                                                    {d.icon !== "" && <span className={`${d.icon} me-2`}></span>}
                                                                                                                    {d.title}
                                                                                                                </Link>
                                                                                                            </li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                    :
                                                                                                    ''
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    {isEmpty(e.image) !== "" ?
                                                                        <div className='megamenu-right sub-menu-img'>
                                                                            <div className='position-relative ad-img text-end'>
                                                                                <img src={isEmpty(e.image)} alt='Fine Jewelry & Diamond Collections' className='img-fluid' />

                                                                                <div className='position-absolute shop-now'>
                                                                                    <h2 className='fs-20px cursor-pointer'><Link onClick={(event) => { routerChange(event, e.banner_url) }} to={e.banner_url} className='text-white'><u>Shop Now</u></Link></h2>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ""}
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <>
                                                            {e.sub_menu.length == 1 || e.product_vertical_name == 'DIY' ?
                                                                <React.Fragment>
                                                                    <div className='d-flex align-items-center justify-content-between '>
                                                                        <Link onClick={() => { sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); if (className === "") { setClassName(e.unique_id); } else { if (className === e.unique_id) { setClassName(""); } else { setClassName(e.unique_id); } } }} className={`nav-link ${className === e.unique_id && "show"} ${isActiveParentMenu(e.router_link, e)
                                                                            ? "active"
                                                                            : ""
                                                                            }`} data-bs-toggle="dropdown">{e.menu_name}
                                                                            <i className={`minus-plus ${className === e.unique_id ? "ic_minus" : "ic_plus"}`}></i>
                                                                        </Link>
                                                                    </div>
                                                                    <div className={`dropdown-content ${className === e.unique_id && "show"}`}>
                                                                        <ul className='p-0'>{
                                                                            e.product_vertical_name === "DIY" ?
                                                                                e.sub_menu.length != 0 && e.sub_menu.map((d, di) =>
                                                                                    d.sub_category === "STRWS" ?
                                                                                        <li key={di} className="inner_li">
                                                                                            <Link onClick={(e) => { routerChange(e, "/start-with-a-setting"); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} to={"/start-with-a-setting"} className={`text-start fs-15px cursor-pointer ${isMenuActive(d.display_name, e) ? "active" : ""}`}>
                                                                                                {/* {d.icon !== "" && <i className={`${d.icon} me-2`}></i>} */}
                                                                                                {d.display_name}
                                                                                            </Link>
                                                                                        </li>
                                                                                        :
                                                                                        <li key={di} className="inner_li">
                                                                                            <Link onClick={(e) => { routerChange(e, "/start-with-a-diamond"); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} to={"/start-with-a-diamond"} className={`text-start fs-15px cursor-pointer ${isMenuActive(d.display_name, e) ? "active" : ""}`}>
                                                                                                {/* {d.icon !== "" && <i className={`${d.icon} me-2`}></i>} */}
                                                                                                {d.display_name}
                                                                                            </Link>
                                                                                        </li>
                                                                                )
                                                                                :
                                                                                e.sub_menu.length != 0 && e.sub_menu[0].detaills.map((d, di) =>
                                                                                    <li key={di} className="inner_li">
                                                                                        <Link onClick={(e) => { routerChange(e, d.router_link); sessionStorage.removeItem('collection_id'); sessionStorage.removeItem('finish_type'); }} to={d.router_link} className={`text-start fs-15px cursor-pointer ${isMenuActive(d.title, e) ? "active" : ""}`}>
                                                                                            {d.icon !== "" && <i className={`${d.icon} me-2`}></i>}
                                                                                            {d.title}
                                                                                        </Link>
                                                                                    </li>
                                                                                )
                                                                        } </ul>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <Link onClick={(s) => { routerChange(s, e.router_link) }} className={`nav-link ${className === e.unique_id && "show"} ${isActiveParentMenu(e.router_link, e)
                                                                    ? "active"
                                                                    : ""
                                                                    }`} to={e.router_link}> {e.menu_name} </Link>
                                                            }
                                                        </>
                                                    }
                                                </li>
                                            </React.Fragment>
                                        ))}

                                    </ul>
                                </div>
                                <div className='nav-item site-header-personal'>
                                    <ul className='navbar-nav menu-icon'>
                                        {/* <li>
                                            <div className='navlink'>
                                                {isSearch ?
                                                    <SearchProducts isSearch={isSearch} setIsSearch={setIsSearch} navMenuFirst={navMenuFirst} />
                                                    :
                                                    ""}
                                                    <div className='box-search'><i className='ic_search' onClick={() => setIsSearch(true)} /></div>
                                            </div>
                                        </li> */}

                                        <li className='nav-item'>
                                            {isLogin ?
                                                <Link className="navlink" aria-label="Go to Dashboard" to={"/dashboard"} onClick={() => { setNavbarToggle(false); setClassName(""); }}>
                                                    {/* <span className='me-2 user_name'>{selector.loginData.first_name}</span> */}

                                                    <i className="ic_my_account cursor-pointer fs-20px position-relative">
                                                        <span className='user_name ic_check'></span>
                                                    </i>
                                                </Link>
                                                :
                                                <Link className="navlink">
                                                    <i className="ic_my_account cursor-pointer fs-20px" aria-label="Go to Dashboard" onClick={() => { setNavbarToggle(false); setClassName(""); showLoginPage(); }}></i>
                                                </Link>
                                            }
                                        </li>

                                        <li className='nav-item'>
                                            <Link className="navlink" to={`/wishList`} aria-label="Go to Dashboard" onClick={() => { setNavbarToggle(false); setClassName(""); }}>
                                                <i className="ic_heart cursor-pointer fs-20px position-relative Header-icon">
                                                    {selector.storeFavCount > 0 && <span className="badge count-icon">
                                                        {selector.storeFavCount}
                                                    </span>}
                                                </i>
                                            </Link>
                                        </li>
                                        <li className='nav-item'>
                                            <div className='navlink' onClick={() => { navigate("/cart"); setNavbarToggle(false); setClassName(""); }}>
                                                <i className="ic_shopping_bag cursor-pointer fs-20px position-relative Header-icon">
                                                    {selector.countCart > 0 && <span className=" badge count-icon">
                                                        {selector.countCart}
                                                    </span>}
                                                </i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div >
            </section >
            {selector.headerLoginModal && <SignIn />}
            < Notification toastMsg={toastMsg} toastShow={toastShow} isSuccess={isSuccess} Close={() => setToastOpen()} />
        </React.Fragment >
    );
}
export default Header;