import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import darkLogo from './../../Assets/Images/ZURAH-LOGO.png';
import { useSelector } from 'react-redux';

const TitleMetaTags = () => {
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    keywords: '',
    image: "",
  });

  const { pathname } = useLocation();
  const selector = useSelector((state) => state)

  useEffect(() => {
    const metaConfig = {
      title: pathname.split("/")[1] || "home",
    };
  
    const generateDynamicMeta = (path) => {
      return {
        title: `${metaConfig.title.toUpperCase()} | ${selector.storeEntityId.store_name}`,
        image: "",
        description: "",
        keywords: "",
      };
    };
  
    const currentMeta = generateDynamicMeta(pathname);
    setMetaData(currentMeta);
  }, [pathname, selector]);
  
  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta name="keywords" content={metaData.keywords} />
      <meta property="og:title" content={metaData.title} />
      <meta property="og:image" content={metaData.image} />
      <meta property="og:description" content={metaData.description} />
      <meta name="twitter:title" content={metaData.title} />
      <meta name="twitter:description" content={metaData.description} />
      <meta name="twitter:image" content={metaData.image} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": metaData.title || "Zurah Jewellery",
          "url": "https://uat.zurahjewellery.com" + location.pathname,
          "logo": metaData.image,
          "description": metaData.description,
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "15205 North Kierland Blvd, Suite 100, Scottsdale",
            "addressLocality": "Scottsdale",
            "addressRegion": "Arizona",
            "postalCode": "85254",
            "addressCountry": "US",
          },
          "telephone": "+1 9876543210",
          "openingHours": ["Mo-Fr 09:00-18:00"],
        })}
      </script> */}
    </Helmet>
  );
};


export default TitleMetaTags;
